window.addEventListener('load', () => {
  //URL の # の位置を取得
  const hashIndex = window.location.href.indexOf('#');

  //URL に # が含まれなければ何もしない
  if (hashIndex === -1) {
    return;
  }

  //#移行の文字列を取得
  const targetId = window.location.href.slice(hashIndex + 1);

  //# 移行が空であればなにもしない
  if (targetId === '') {
    return;
  }

  //対象を取得
  const target = document.getElementById(targetId);

  //対象が無ければ何もしない
  if ( ! target) {
    return;
  }

  //ヘッダーがあるか判定
  const headers = document.getElementsByTagName('header');
  const headerHeight = headers.item(0) ? headers.item(0).getBoundingClientRect().height + 10 : 0;

  //対象までスクロール
  window.scrollTo(0, window.scrollY + target.getBoundingClientRect().top - headerHeight);
});

//a タグのページ内スクロール
document.addEventListener('click', event => {
  //event が Event でなければ何もしない
  if ( ! (event instanceof Event)) {
    return;
  }

  //自身 ~ 親を遡って HTMLAnchorElement を探す
  const _findAnchor = current => {
    //HTMLElement で無かったら null を返す
    if ( ! (current instanceof HTMLElement)) {
      return null;
    }

    //目当ての HTMLAnchorElement が存在すればそれを返す
    if (current instanceof HTMLAnchorElement) {
      return current;
    }

    //親を取得し、再帰
    return _findAnchor(current.parentElement);
  };

  //アンカーの取得
  const anchor = _findAnchor(event.target);

  //a タグが見つからなかったら何もしない
  if ( ! (anchor instanceof HTMLAnchorElement)) {
    return;
  }

  //hash が存在しないかつ href が # 単発でない場合は何もしない
  if (anchor.hash === '' && anchor.getAttribute('href') !== '#') {
    return;
  }

  //hash が存在しても、 hash よりも前の URL が現在の URL と異なる場合は何もしない
  if (anchor.href.replace(/\/\?/, '?').substring(0, anchor.href.replace(/\/\?/, '?').indexOf('#')) !== window.location.href.replace(/\/\?/, '?').substring(0, window.location.href.replace(/\/\?/, '?').indexOf('#'))) {
    return;
  }

  //スムーズスクロールを実行する関数
  const scroll = end => {
    //スクロールに掛かるミリ秒を設定
    const MILLISECONDS = 500;

    //現在のスクロール位置を取得
    const start = window.scrollY;

    //スクロール位置の差を算出
    const diff = end - start;

    //現在時刻を取得
    const startTime = (new Date()).getTime();

    //アニメーション終了時刻を算出
    const endTime = startTime + MILLISECONDS;

    /**
     * 0~1の時間(t)に対してイージング加工された値を返す
     * @param t
     * @private
     */
    const easeInOutQuad = t => {
      return t <.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t;
    };

    //アニメーション関数
    const loop = () => {
      //この関数をループ
      const animation = requestAnimationFrame(loop);

      //もし現在時刻が開始時刻 + MILLISECONDS を超えていたら終了
      if (endTime < (new Date()).getTime()) {
        //アニメーションを終了
        cancelAnimationFrame(animation);

        //目的位置でストップ
        window.scrollTo(0, end);

        //処理終了
        return;
      }

      //経過時間から終了時間までの間を0 ~ 1割合で算出
      const passage = (new Date().getTime() - startTime) / MILLISECONDS;

      //現在時刻から算出された位置にスクロール
      window.scrollTo(0, start + Math.round(diff * easeInOutQuad(passage)));
    };

    //アニメーション開始
    loop();
  };

  //# 単体だったら TOP へスクロール
  if (anchor.getAttribute('href') === '#') {
    event.preventDefault();
    history.pushState('','',anchor.href);
    scroll(0);
    return;
  }

  //ヘッダーがあるか判定
  const headers = document.getElementsByTagName('header');
  const headerHeight = headers.item(0) ? headers.item(0).getBoundingClientRect().height + 10 : 0;

  //対象があるか判定
  const target = document.getElementById(anchor.hash.slice(1));

  //対象が無ければ何もしない
  if ( ! target) {
    return;
  }

  //対象の位置までスクロール
  event.preventDefault();
  history.pushState('','',anchor.href);
  scroll(window.scrollY + target.getBoundingClientRect().top - headerHeight);
});
